import Box from "../../../../common/components/Box";
import Heading from "../../../../common/components/Heading";
import Text from "../../../../common/components/Text";
import Container from "../../../../common/components/UI/Container";
import { SECTION_ABOUT } from "../../../../common/data/WebApp";
import { Link } from "gatsby";
// import { StaticImage } from "gatsby-plugin-image";

import React from "react";
import { Icon } from "react-icons-kit";
import { androidArrowForward } from "react-icons-kit/ionicons/androidArrowForward";
import SectionArea, {
  Row,
  Col,
  TopHeading,
  ColumnContents,
} from "./section.style";
const Section = () => {
  const { sections } = SECTION_ABOUT;
  return (
    <>
      <SectionArea id="ListCareers">
        <Container>
          <Row className="rowContents">
            <TopHeading>
              <Heading as="h2" content="Careers available at x-RD" />
            </TopHeading>
          </Row>
          <Row>
            <Box className="blockTitle">
              <Text
                as="p"
                content="LinkedIn - Click the button below to see available positions."
              />
              <Link
                className="button"
                target="_blank"
                to="https://www.linkedin.com/company/x-rd/jobs"
              >
                <span>
                  LinkedIn Positions
                  <Icon icon={androidArrowForward} size={16} />
                </span>
              </Link>
            </Box>
          </Row>
        </Container>
      </SectionArea>
    </>
  );
};

export default Section;
